import { render, staticRenderFns } from "./block.vue?vue&type=template&id=6aa80256&scoped=true"
import script from "./block.vue?vue&type=script&lang=js"
export * from "./block.vue?vue&type=script&lang=js"
import style0 from "./block.vue?vue&type=style&index=0&id=6aa80256&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aa80256",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCol,VContainer,VRow,VTooltip})
