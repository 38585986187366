<template>
  <div>
    <h5 :class="getBlockNameClass">{{ blockName }}</h5>
    <v-container fluid flex>
      <v-row class="flex-nowrap justify-left align-left">
        <v-col
          v-for="(spot, index) in block"
          :key="index"
          xs="1"
          cols="auto"
          class="pr-0"
          :style="{ 'padding-left': blockPadLeft + 'px' }"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div>
                <spot :spot="spot"></spot>
                <div style="transform: rotate(90deg);position: relative;
                    bottom: 42px;
                    width: 12px;
                    opacity: 0;
                    z-index: 2;">
                  <span
                    v-bind="attrs"
                    v-if="spot.equipment.length > 0"
                    v-on="on"
                  >Hover</span>
                </div>
              </div>
            </template>
            <div v-if="spot.equipment.length > 0">
            <span>Spot: {{ spot.displayName }}</span>
            <span><br />Number: {{ spot.equipment[0].number }}</span>
            <span><br />Type: {{ spot.equipment[0].type }}</span>
            <span><br />Status: {{ spot.equipment[0].state }}</span>
            <span><br />Size: {{ spot.equipment[0].size }}</span>
            <span v-if="spot.equipment[0].carrier.name">
              <br />Carrier Name: {{ spot.equipment[0].carrier.name }}
            </span>
            <span v-if="spot.equipment[0].carrier.scac">
              <br />Carrier SCAC: {{ spot.equipment[0].carrier.scac }}
            </span>
            </div>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import spot from './spot.vue';

export default {
  data: () => ({
    blockPadLeft: 3,
    blockNameClass: 'd-flex justify-center align-center',
  }),
  props: ['blockName', 'block'],
  components: { spot },
  computed: {
    ...mapGetters('location', ['filter', 'locationByEquipmentNumber']),
    getBlockNameClass() {
      const location = this.locationByEquipmentNumber(this.filter);
      const isFilteredLocationInBlock = location && this.block.find((w) => w.id === location.id);
      if (isFilteredLocationInBlock) {
        return `${this.blockNameClass} highlighted`;
      }

      return this.blockNameClass;
    },
  },
};
</script>
<style scoped>
.highlighted {
  background-color: black;
  color: white;
}
</style>
