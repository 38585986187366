<template>
  <div style="overflow-x: auto">
    <v-progress-circular
          style="overflow: hidden;"
          indeterminate
          size="100"
          v-if="isLoading"
          class="offset-md-5"
        ></v-progress-circular>
    <v-container fluid flex class="pl-0" v-if="!isLoading">
      <v-row class="pl-3" style="position: absolute;">
        <v-sheet elevation="2" height="20" width="20" color="blue darken-3"></v-sheet>
        &nbsp;&nbsp;&nbsp;
        <span>Loaded</span>
        &nbsp;&nbsp;&nbsp;
        <v-sheet elevation="2" height="20" width="20" color="blue lighten-2"></v-sheet>
        &nbsp;&nbsp;&nbsp;
        <span>Empty</span>
        &nbsp;&nbsp;&nbsp;
        <v-sheet elevation="2" height="20" width="20" color="yellow"></v-sheet>
        &nbsp;&nbsp;&nbsp;
        <span>Scheduled Move To</span>
        &nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;
        <v-avatar color="grey" size="24">{{this.getEquipmentTypeCount(this.container)}}</v-avatar>
        &nbsp;&nbsp;<span>Containers</span>
        &nbsp;&nbsp;&nbsp;
        <v-avatar color="grey" size="24">{{this.getEquipmentTypeCount(this.chassis)}}</v-avatar>
        &nbsp;&nbsp;<span>Chassis</span>
        &nbsp;&nbsp;&nbsp;
        <v-avatar color="grey" size="24">{{this.getEquipmentTypeCount(this.van)}}</v-avatar>
        &nbsp;&nbsp;<span>Vans</span>
        <v-text-field
        v-model="searchTerm"
        class="pt-0 mt-0 pl-3"
        style="width: 20em;"
        append-icon="mdi-magnify"
        label="Search by equipment number..."
        single-line
        hide-details
      ></v-text-field>
      </v-row>
      <v-row class="flex-nowrap justify-left align-left pt-5">
        <v-col cols="auto">
          <block :blockName="'BLOCK 800'" :block="block800"></block>
        </v-col>
        <v-col class="pl-5 pr-0 justify-left align-left" cols="auto">
          <block :blockName="'BLOCK 700'" :block="block700"></block>
        </v-col>
      </v-row>
      <v-row class="flex-nowrap justify-left align-left">
        <v-col cols="auto">
          <block :blockName="'BLOCK 600'" :block="block600"></block>
        </v-col>
        <v-col class="pl-5 pr-0 justify-left align-left" cols="auto">
          <block :blockName="'BLOCK 500'" :block="block500"></block>
        </v-col>
      </v-row>
      <v-row class="flex-nowrap justify-left align-left">
        <v-col cols="auto">
          <block :blockName="'BLOCK 400'" :block="block400"></block>
        </v-col>
        <v-col class="pl-5 pr-0 justify-left align-left" cols="auto">
          <block :blockName="'BLOCK 300'" :block="block300"></block>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center align-center">
          <block :blockName="'EAST DOORS'" :block="eastDoors2"></block>
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <block :blockName="'EAST DOORS'" :block="eastDoors1"></block>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center align-center">
          <block :blockName="'WEST DOORS'" :block="westDoors2"></block>
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <block :blockName="'WEST DOORS'" :block="westDoors1"></block>
        </v-col>
      </v-row>
      <v-row class="flex-nowrap justify-left align-left">
        <v-col cols="auto">
          <block :blockName="'BLOCK 200'" :block="block200"></block>
        </v-col>
        <v-col class="pl-5 pr-0 justify-left align-left" cols="auto">
          <block :blockName="'BLOCK 100'" :block="block100"></block>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import block from '../components/block.vue';
import equipmentTypes from '../constants/equipmentTypes';
import router from '../router/index';

export default {
  data: () => ({
    spotWidth: 12,
    spotHeight: 20,
    spotFontSize: 10,
    blockPadLeft: 3,
    doorPadLeft: 6,
    container: equipmentTypes.CONTAINER,
    van: equipmentTypes.VAN,
    chassis: equipmentTypes.CHASSIS,
  }),
  components: { block },
  computed: {
    searchTerm: {
      get() {
        return this.filter;
      },
      set(value) {
        this.setFilter(value);
      },
    },
    filteredLocation() {
      return this.locationByEquipmentNumber(this.searchTerm);
    },
    block800: {
      get() {
        const groupLocations = _.orderBy(this.$store.getters['location/locationsByGroup']('Block 800'), 'displayName').reverse();

        if (this.filteredLocation) {
          if (this.hasFilteredLocationInGroup(groupLocations)) {
            return [this.filteredLocation];
          }

          return [];
        }

        return groupLocations;
      },
    },
    block700: {
      get() {
        const groupLocations = _.orderBy(this.$store.getters['location/locationsByGroup']('Block 700'), 'displayName').reverse();

        if (this.filteredLocation) {
          if (this.hasFilteredLocationInGroup(groupLocations)) {
            return [this.filteredLocation];
          }

          return [];
        }

        return groupLocations;
      },
    },
    block600: {
      get() {
        const groupLocations = _.orderBy(this.$store.getters['location/locationsByGroup']('Block 600'), 'displayName').reverse();

        if (this.filteredLocation) {
          if (this.hasFilteredLocationInGroup(groupLocations)) {
            return [this.filteredLocation];
          }

          return [];
        }

        return groupLocations;
      },
    },
    block500: {
      get() {
        const groupLocations = _.orderBy(this.$store.getters['location/locationsByGroup']('Block 500'), 'displayName').reverse();

        if (this.filteredLocation) {
          if (this.hasFilteredLocationInGroup(groupLocations)) {
            return [this.filteredLocation];
          }

          return [];
        }

        return groupLocations;
      },
    },
    block400: {
      get() {
        const groupLocations = _.orderBy(this.$store.getters['location/locationsByGroup']('Block 400'), 'displayName').reverse();

        if (this.filteredLocation) {
          if (this.hasFilteredLocationInGroup(groupLocations)) {
            return [this.filteredLocation];
          }

          return [];
        }

        return groupLocations;
      },
    },
    block300: {
      get() {
        const groupLocations = _.orderBy(this.$store.getters['location/locationsByGroup']('Block 300'), 'displayName').reverse();

        if (this.filteredLocation) {
          if (this.hasFilteredLocationInGroup(groupLocations)) {
            return [this.filteredLocation];
          }

          return [];
        }

        return groupLocations;
      },
    },
    block200: {
      get() {
        const groupLocations = _.orderBy(this.$store.getters['location/locationsByGroup']('Block 200'), 'displayName').reverse();

        if (this.filteredLocation) {
          if (this.hasFilteredLocationInGroup(groupLocations)) {
            return [this.filteredLocation];
          }

          return [];
        }

        return groupLocations;
      },
    },
    block100: {
      get() {
        const groupLocations = _.orderBy(this.$store.getters['location/locationsByGroup']('Block 100'), 'displayName').reverse();

        if (this.filteredLocation) {
          if (this.hasFilteredLocationInGroup(groupLocations)) {
            return [this.filteredLocation];
          }

          return [];
        }

        return groupLocations;
      },
    },
    westDoors1: {
      get() {
        const locations = _.filter(this.$store.getters['location/locationsByGroup']('West Door'), (locations) => locations.displayName <= 55);
        this.padLocationsDisplayName(locations);
        const groupLocations = _.orderBy(locations, 'displayName').reverse();

        if (this.filteredLocation) {
          if (this.hasFilteredLocationInGroup(groupLocations)) {
            return [this.filteredLocation];
          }

          return [];
        }

        return groupLocations;
      },
    },
    westDoors2: {
      get() {
        const locations = _.filter(this.$store.getters['location/locationsByGroup']('West Door'), (locations) => locations.displayName > 55);
        this.padLocationsDisplayName(locations);
        const groupLocations = _.orderBy(locations, 'displayName').reverse();

        if (this.filteredLocation) {
          if (this.hasFilteredLocationInGroup(groupLocations)) {
            return [this.filteredLocation];
          }

          return [];
        }

        return groupLocations;
      },
    },
    eastDoors1: {
      get() {
        const locations = _.filter(this.$store.getters['location/locationsByGroup']('East Door'), (locations) => locations.displayName <= 56);
        this.padLocationsDisplayName(locations);
        const groupLocations = _.orderBy(locations, 'displayName').reverse();

        if (this.filteredLocation) {
          if (this.hasFilteredLocationInGroup(groupLocations)) {
            return [this.filteredLocation];
          }

          return [];
        }

        return groupLocations;
      },
    },
    eastDoors2: {
      get() {
        const locations = _.filter(this.$store.getters['location/locationsByGroup']('East Door'), (locations) => locations.displayName > 56);
        this.padLocationsDisplayName(locations);
        const groupLocations = _.orderBy(locations, 'displayName').reverse();

        if (this.filteredLocation) {
          if (this.hasFilteredLocationInGroup(groupLocations)) {
            return [this.filteredLocation];
          }

          return [];
        }

        return groupLocations;
      },
    },
    ...mapGetters('location', [
      'isLoading',
      'groups',
      'filter',
      'locationByEquipmentNumber',
    ]),
    // HACK: Remove when overview is accessible for more than 5816
    ...mapGetters('account', ['selectedYard']),
  },
  methods: {
    ...mapActions('sidenav', ['setIsSideNavVisible']),
    ...mapActions('location', ['setFilter']),
    getEquipmentTypeCount(equipmentType) {
      return this.$store.getters['location/equipmentTypeCount'](equipmentType);
    },
    padLocationsDisplayName(locations) {
      const maxLength = _.max(_.map(locations, (location) => location.displayName.length));
      for (let i = 0; i < locations.length; i += 1) {
        const displayNameLength = locations[i].displayName.length;
        if (displayNameLength < maxLength) {
          locations[i].displayName = locations[i].displayName.padStart(maxLength, ' ');
        }
      }
    },
    // HACK: Remove when overview is accessible for more than 5816
    isOverviewAccessible() {
      const yardId5816 = '5816';
      return this.selectedYard.yardId === yardId5816;
    },
    hasFilteredLocationInGroup(blockLocations) {
      const hasFilteredLocationInGroup = blockLocations.find((location) => location.id === this.filteredLocation.id) !== undefined;
      if (hasFilteredLocationInGroup) {
        return true;
      }

      return false;
    },
  },
  mounted() {
    this.setIsSideNavVisible(false);
  },
  // HACK: Remove when overview is accessible for more than 5816
  created() {
    if (!this.isOverviewAccessible()) {
      router.push('/gate');
    }
  },
  beforeDestroy() {
    this.setFilter('');
  },
};
</script>
