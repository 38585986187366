<template>
  <v-sheet
    :color="getSpotColor(spot)"
    elevation="1"
    :height="spotHeight"
    :width="spotWidth"
    class="d-flex justify-center align-center"
    :style="{
      cursor: 'pointer',
      'font-size': spotFontSize + 'px',
    }"
  >
  <v-container>
<v-row>
  <v-col class="pl-2 pr-0" style="transform: rotate(90deg);">
    <span>
      {{ spot.displayName }}
    </span>
    </v-col>
    <v-col class="pt-0 pb-0 pl-2" v-if="spot.equipment.length > 0">
      <span style="font-size: 9px; font-weight: bold;">{{spot.equipment[0].abbreviation}}</span>
    </v-col>
    </v-row>
    </v-container>
  </v-sheet>
  </template>
<script>
import equipmentStates from '../constants/equipmentStates';

export default {
  props: ['spot'],
  data: () => ({
    spotWidth: 12,
    spotHeight: 45,
    spotFontSize: 10,
  }),
  methods: {
    getSpotColor(location) {
      if (location.equipment.length > 0 && location.equipment[0].state === equipmentStates.LOADED) {
        return 'blue darken-3';
      }
      if (location.equipment.length > 0 && location.equipment[0].state === equipmentStates.EMPTY) {
        return 'blue lighten-2';
      }
      if (location.hasMoveTo) {
        return 'yellow';
      }

      return 'white';
    },
  },
};
</script>
